import React from 'react'
import './App.css'
import Countdown from 'react-countdown'
import { Typography } from 'antd'

const { PUBLIC_URL } = process.env

function App() {
  const date = new Date('2024-11-05T08:00:00-05:00')

  return (
    <div className="wrapper">
      <div className="body">
        <img src={`${PUBLIC_URL}/image.jpg`} className="logo" alt="logo" />
        <Countdown date={date} />
      </div>
      <div className="footer">
        <Typography.Link href="https://t.me/TheFWP" target="_blank">
          Telegram
        </Typography.Link>
        <Typography.Link href="https://t.me/TheFWP" target="_blank">
          Twitter
        </Typography.Link>
      </div>
    </div>
  )
}

export default App
